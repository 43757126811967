
















import { Component, Vue } from "vue-property-decorator";

@Component
export default class Details extends Vue {
  book: any;

  created() {
    this.book = {
      title: this.$route.query.title,
      cover_id: this.$route.query.cover_id,
      authors: this.$route.query.authors,
      year: this.$route.query.year,
    };
  }

  getImageSrc() {
    return (
      "http://covers.openlibrary.org/b/OLID/" + this.book.cover_id + "-M.jpg"
    );
  }
}
